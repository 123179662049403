import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FormService, VisitDetailsService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { VisitDetailsBlasting } from '@app/models/patient/visit-details/blasting.model';
import { ItemsComponent } from '@app/core/components/items/items.component';
import { WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';
import { VisitStatus } from '@app/models/visits/visits.model';
import { BlastingStatus } from "@app/models/blasting/blasting-visit.model";

@UntilDestroy()
@Component({
  selector: 'app-blasting-tab',
  templateUrl: './blasting-tab.component.html',
  styleUrls: ['./blasting-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class BlastingTabComponent  extends ItemsComponent<VisitDetailsBlasting> {
  @Input() visitId: number;
  @Input() visitStatus: number;

  selectedCaregiver: number;
  isAllBlasting: boolean;
  isCaregiverBlasting: boolean;
  expandedElement: VisitDetailsBlasting | null;

  readonly displayedColumns = ['number', 'date', 'author', 'offers', 'status', 'action'];
  readonly BlastingStatuses = BlastingStatus;
  readonly visitStatuses = VisitStatus;

  protected realtimeModules = [{ module: WsConnectionModule.active_blasting }];
  protected updateItemRealtimeEvents = [
    {
      name: WsEvent.visit_blasting,
      filter: (message => message.payload.visit === this.visitId)
    }
  ];
  protected createItemRealtimeEvents = this.updateItemRealtimeEvents;

  constructor(
    private visitDetailsService: VisitDetailsService,
    private formService: FormService,
  ) {
    super();
  }

  loadItems(params?: any) {
    this.items = [];
    super.loadItems(params);
  }

  protected getItems(): Observable<PaginatedResponse<VisitDetailsBlasting>> {
    const params = { visit: this.visitId, offset: 0, limit: 1000 };
    return this.visitDetailsService.getVisitBlasting(params);
  }

  blastingCaregiver(): void {
    this.isCaregiverBlasting = true;
    this.sendBlasting([this.selectedCaregiver]);
  }

  protected createItem(newItem: VisitDetailsBlasting) {
    super.createItem(newItem);
  }

  blastingToAll(): void {
    this.isAllBlasting = true;
    this.sendBlasting([]);
  }

  private sendBlasting(caregivers: number[]): void {
    const payload = {
      visit: this.visitId,
      caregivers
    };
    this.visitDetailsService.createVisitBlasting(payload).pipe(
      finalize(() => {
        this.isCaregiverBlasting = false;
        this.isAllBlasting = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe({
      error: (error: HttpErrorResponse) => {
        this.formService.nonFieldErrors(error);
        this.cdr.detectChanges();
      }
    });
  }

  onRowClick(element: VisitDetailsBlasting): void {
    this.expandedElement = this.expandedElement?.id === element.id ? null : element;
  }

  changeBlastingStatus(event: MouseEvent, id: number, status: number): void {
    event.stopPropagation();
    this.visitDetailsService.changeVisitDetailsBlastingStatus(id, { status })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => this.updateItem({ id, ...response }),
        error: (error) => this.notificationsService.showError(error)
      });
  }

  caregiverSelectChange(event: { value: number, label: string }): void {
    if (event) {
      this.selectedCaregiver = event.value;
    }
  }
}
