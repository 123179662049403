import { Directive, OnInit, Optional, Self, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appTrimInput]',
})
export class TrimInputDirective implements OnInit {
  pattern: RegExp = /^[ \t]+/;
  @Input('appTrimInput') set regexp(pattern: string | RegExp) {
    if (!!pattern) {
      this.pattern = new RegExp(pattern, 'gm');
    }
  }
  constructor(
    @Optional() @Self() private ngControl: NgControl
  ) { }

  ngOnInit(): void {
    if (this.ngControl) {
      this.ngControl.valueChanges.pipe(
        untilDestroyed(this)
      ).subscribe((value: string) => {
          if (!!value?.replace) {
            this.ngControl.control.setValue(value.replace(this.pattern, ''), { emitEvent: false });
          }
        });
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.ngControl) {
      this.ngControl.control.setValue((this.ngControl.value || '').trim(), { emitEvent: false });
    }
  }
}
