import { TIME_ZONE } from '@app/common';
import { MenuItem } from '@app/models/main-menu.model';
import { AuthorizationStatus, MeasurementType } from '@app/models/patient/authorizations.model';
import { ReportType } from '@app/models/reports/reports.model';
import { DiagnoseDateType, DiagnoseIdentified } from '@app/models/patient/diagnosis.model';
import { PayerStatus, PayerType } from '@app/models/payer/payer.model';
import { PatientStatus } from '@app/models/patient/patient.model';
import { CaregiverPayrollExclusionType } from '@app/models/users/caregiver-payroll.model';
import {
  EdiClaimType,
  EdiProviderProcessingMethod,
  EdiProviderType
} from '@app/models/settings/billing/edi-provider.model';
import { MaritalStatus } from '@app/models/users/edit-user-data.model';
import { MonthEnum } from '@app/models/month.model';
import { GenderEnum } from '@app/models/gender.model';
import { AvailableStatusEnum } from '@app/models/dispatch-screen/dispatch-screen-visit.model';
import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import {
  ADocumentOption,
  BCDocumentOption,
  ComplianceReferenceOption,
  CriminalBackgroundResultOption,
  ShotOption
} from '@app/models/users/caregiver-compliance.model';
import { NotificationSubType, NotificationType } from '@app/models/notifications/notification.model';
import { DowntimeInterval, NoticeTime, TransportationType } from '@app/models/settings/assign-blasting-setting.model';
import { ExpenseType } from '@app/models/users/caregiver-expense.model';
import { VisitStatus } from '@app/models/visits/visits.model';
import { UserType } from '@app/models/users/user-profile.model';
import { UserStatus } from '@app/models/users/user.ifc';
import { SmokerType } from '@app/models/users/caregiver.model';
import { AuthorizationPeriod, InServiceStatus } from '@app/core/enums';
import { BlastingStatus } from '@app/models/blasting/blasting-visit.model';

export const selectNotFoundText = 'commonNotifications.noResultsFound';

export const PHONE_MASK = {
  mask: '(000) 000-0000',
  lazy: false,
  overwrite: true
};

export const SITE_THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const MAP_THEMES = {
  LIGHT: 'theme-light',
  DARK: 'theme-dark'
};

export const USER_TYPES_CHOICES = [
  { value: UserType.Administrator, checked: true, label: 'userTypes.administrator' },
  { value: UserType.Coordinator, checked: true, label: 'userTypes.coordinator' },
  { value: UserType.Payroll, checked: true, label: 'userTypes.payroll' },
  { value: UserType.Biller, checked: true, label: 'userTypes.biller' },
  { value: UserType.Caregiver, checked: true, label: 'userTypes.caregiver' },
  { value: UserType.Trainee_Applicant, checked: true, label: 'userTypes.applicant' },
  { value: UserType.HR, checked: true, label: 'userTypes.hr' },
  { value: UserType.Instructor, checked: true, label: 'userTypes.instructor' },
  { value: UserType.CaseManager, checked: true, label: 'userTypes.caseManager' },
  { value: UserType.ServiceCoordinator, checked: true, label: 'userTypes.serviceCoordinator' }
];

export const COORDINATOR_TYPES_CHOICES = [
  { value: 0, label: 'coordinatorTypes.paCoordinator', checked: false },
  { value: 1, label: 'coordinatorTypes.paceCoordinator', checked: false },
  { value: 2, label: 'coordinatorTypes.waiverCoordinator', checked: false }
];

export const PAYER_TYPE_CHOICES = [
  { value: PayerType.EDI, label: 'payers.type_edi' },
  { value: PayerType.PrivatePay, label: 'payers.type_privatePayer' }
];

export const CLAIM_TYPE_CHOICES = [
  { value: EdiClaimType.Professional, label: 'payers.claimType_professional' },
  { value: EdiClaimType.Institutional, label: 'payers.claimType_institutional' }
];

export const EXPENSES_TYPES_CHOICES = [
  { value: ExpenseType.TRAVEL_TIME, label: 'caregiverDetails.expenses_popup_type_travelTime' },
  { value: ExpenseType.VACATION_PAY, label: 'caregiverDetails.expenses_popup_type_vacationPay' },
  { value: ExpenseType.TRAVELING_FOR_A_NEW_AID_CAREGIVER, label: 'caregiverDetails.expenses_popup_type_travelingForNewAid' },
  { value: ExpenseType.SUPERVISOR_VISIT, label: 'caregiverDetails.expenses_popup_type_supervisorVisit' },
  { value: ExpenseType.SIGN_ON_BONUS, label: 'caregiverDetails.expenses_popup_type_signInBonus' },
  { value: ExpenseType.SEXUAL_HARASSMENT_TRAINING, label: 'caregiverDetails.expenses_popup_type_sexualHarassmentTraining' },
  { value: ExpenseType.REFERRAL, label: 'caregiverDetails.expenses_popup_type_referral' },
  { value: ExpenseType.PTO_POLICY, label: 'caregiverDetails.expenses_popup_type_ptoPolicy' },
  { value: ExpenseType.PFML_COVID_VACCINE, label: 'caregiverDetails.expenses_popup_type_covidVaccine' },
  { value: ExpenseType.PAY_RATE_MISTAKE, label: 'caregiverDetails.expenses_popup_type_payRateMistake' },
  { value: ExpenseType.PAID_SICK_LEAVE, label: 'caregiverDetails.expenses_popup_type_paidSickLeave' },
  { value: ExpenseType.ON_CALL_BONUS, label: 'caregiverDetails.expenses_popup_type_onCallBonus' },
  { value: ExpenseType.OFFICE_TIME, label: 'caregiverDetails.expenses_popup_type_officeTime' },
  { value: ExpenseType.NURSING_OPEN, label: 'caregiverDetails.expenses_popup_type_nursingOpen' },
  { value: ExpenseType.MILEAGE, label: 'caregiverDetails.expenses_popup_type_mileage' },
  { value: ExpenseType.INSERVICE, label: 'caregiverDetails.expenses_popup_type_inservice' },
  { value: ExpenseType.BUS_PASS_DEDUCTION, label: 'caregiverDetails.expenses_popup_type_busPassDeduction' },
  { value: ExpenseType.BTI_HOURS, label: 'caregiverDetails.expenses_popup_type_btiHours' },
  { value: ExpenseType.BAD_WEATHER_MANDATION_BONUS, label: 'caregiverDetails.expenses_popup_type_badWeatherMandationBonus' },
  { value: ExpenseType.PTO_PERSONAL_TIME_OFF, label: 'caregiverDetails.expenses_popup_type_PTO' },
  { value: ExpenseType.WAIVER_TRAINING_EXPENSES, label: 'caregiverDetails.expenses_popup_type_waiverTrainingExpenses' },
  { value: ExpenseType.LOAN, label: 'caregiverDetails.expenses_popup_type_loan' },
  { value: ExpenseType.HOLIDAY_PAY, label: 'caregiverDetails.expenses_popup_type_holidayPay' },
  { value: ExpenseType.RETRO_PAY, label: 'caregiverDetails.expenses_popup_type_retroPay' }
];

export const EXCLUSION_TYPE_CHOICES = [
  { value: CaregiverPayrollExclusionType.Time, label: 'userDetails.caregiverPayroll_popup_field_exclusionType_time' },
  { value: CaregiverPayrollExclusionType.PatientWithAuth, label: 'userDetails.caregiverPayroll_popup_field_exclusionType_patientAuth' },
];

export const ONE_TO_TEN_NUMBERS_CHOICES = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

export const APPLICANT_STATUS_CHOICES = [
  { value: 0, checked: true, label: 'applicants.applicantStatus_new' },
  { value: 1, checked: true, label: 'applicants.applicantStatus_pending' },
  { value: 2, checked: true, label: 'applicants.applicantStatus_interviewScheduling' },
  { value: 3, checked: true, label: 'applicants.applicantStatus_interviewScheduled' },
  { value: 4, checked: true, label: 'applicants.applicantStatus_interviewArrived' },
  { value: 5, checked: true, label: 'applicants.applicantStatus_hired' },
  { value: 6, checked: true, label: 'applicants.applicantStatus_rejected' }
];

export const BATCH_ISSUES_CHOICES = [
  { value: 0, label: 'mainBatch.batchIssue_unbalancedVisits', checked: true },
  { value: 1, label: 'mainBatch.batchIssue_incompleteConfirmation', checked: true },
  { value: 2, label: 'mainBatch.batchIssue_withTempCaregiver', checked: true },
  { value: 3, label: 'mainBatch.batchIssue_caregiverCompliance', checked: true },
  { value: 4, label: 'mainBatch.batchIssue_pocCompliance', checked: true },
  { value: 5, label: 'mainBatch.batchIssue_overlappingShifts', checked: true },
  { value: 6, label: 'mainBatch.batchIssue_otTtNotApproved', checked: true },
  { value: 7, label: 'mainBatch.batchIssue_restrictedCaregivers', checked: true },
  { value: 8, label: 'mainBatch.batchIssue_insufficientDutyMinutes', checked: true },
  { value: 9, label: 'mainBatch.batchIssue_authorization', checked: true },
  { value: 10, label: 'mainBatch.batchIssue_uncompletedVisits', checked: true },
  { value: 11, label: 'mainBatch.batchIssue_unassignedCaregivers', checked: true },
  { value: 12, label: 'mainBatch.batchIssue_payrollTemplate', checked: true },
  { value: 13, label: 'mainBatch.batchIssue_endOfVisitNotConfirmed', checked: true }
];

export const MEASUREMENT_TYPE_CHOICES = [
  { value: MeasurementType.Units, label: 'userDetails.authorizations_popup_measurementType_units' },
  { value: MeasurementType.Hours, label: 'userDetails.authorizations_popup_measurementType_hours' }
];

export enum BaseStatus {
  Active = 0,
  Inactive = 1,
}

export enum DischargedStatus {
  Withdrawn = 2,
  Terminated = 3
}

export const BASE_STATUS_CHOICES = [
  { value: BaseStatus.Active, label: 'visitStatuses.active' },
  { value: BaseStatus.Inactive, label: 'visitStatuses.inactive' }
];

export const MASTERWEEK_PAYER_STATUS_CHOICES = [
  ...BASE_STATUS_CHOICES,
  { value: 2, label: 'visitStatuses.expired' }
];

export const MESSAGES_TYPES = [
  { value: 0, label: 'Text Message' },
  // { value: 1, label: 'Voice message' } // Temporary commented out
];

export const DUTY_STATUS_CHOICES = [...BASE_STATUS_CHOICES];
export const PHYSICIANS_STATUS_CHOICES = [...BASE_STATUS_CHOICES];
export const ALLERGY_STATUS_CHOICES = [...BASE_STATUS_CHOICES];
export const OFFICE_STATUS_CHOICES = [...BASE_STATUS_CHOICES];
export const DISCHARGED_STATUS_CHOICES = [
  { value: DischargedStatus.Withdrawn, label: 'userDetails.userStatus_withdraw' },
  { value: DischargedStatus.Terminated, label: 'userDetails.userStatus_terminated' }
];

export const USER_STATUSES_CHOICES = [
  { value: UserStatus.Active, label: 'common.userStatus_active' },
  { value: UserStatus.Rejected, label: 'common.userStatus_rejected' },
  { value: UserStatus.TerminatedInvoluntary, label: 'common.userStatus_terminatedInvoluntary' },
  { value: UserStatus.TerminatedVoluntary, label: 'common.userStatus_terminatedVoluntary' },
  { value: UserStatus.Applicant, label: 'common.userStatus_applicant' },
  { value: UserStatus.MedicalLeave, label: 'common.userStatus_medicalLeave' },
  { value: UserStatus.EligibleForRehire, label: 'common.userStatus_eligibleForRehire' }
];

export const YES_NO_CHOICES_BOOLEAN = [
  { value: true, label: 'common.yes' },
  { value: false, label: 'common.no' }
];

export const YES_NO_CHOICES_STRING = [
  { value: 'yes', label: 'common.yes', checked: true },
  { value: 'no', label: 'common.no', checked: true }
];

export const GENDER_CHOICES = [
  { value: GenderEnum.Male, label: 'common.gender_male' },
  { value: GenderEnum.Female, label: 'common.gender_female' },
  { value: GenderEnum.Other, label: 'common.gender_other' }
];

export const GENDER_PREFERRED_CHOICES = [
  { value: GenderEnum.Male, label: 'common.gender_male' },
  { value: GenderEnum.Female, label: 'common.gender_female' },
  { value: GenderEnum.Any, label: 'common.gender_any' }
];

export const GENDER_PREFERRED_TO_WORK_CHOICES = [
  { value: GenderEnum.Male, label: 'common.gender_male' },
  { value: GenderEnum.Female, label: 'common.gender_female' },
  { value: GenderEnum.Any, label: 'common.gender_any' }
];

export const CONTACT_METHOD_CHOICES = [
  { value: 1, label: 'common.contactMethod_call' },
  { value: 2, label: 'common.contactMethod_textMessage' },
  { value: 0, label: 'common.contactMethod_email' },
];

export const PATIENT_TYPE_CHOICES = [
  { value: 0, label: 'patients.patientType_referral', checked: true },
  { value: 1, label: 'patients.patientType_patient', checked: true }
];

export const PATIENT_STATUS_CHOICES = [
  { value: PatientStatus.Active, label: 'patients.patientStatus_active', checked: true },
  { value: PatientStatus.Hold, label: 'patients.patientStatus_hold', checked: true },
  { value: PatientStatus.Discharged, label: 'patients.patientStatus_discharged', checked: true },
  { value: PatientStatus.HospitalizedObservation, label: 'patients.patientStatus_hospitalizedObservation', checked: true },
  { value: PatientStatus.HospitalizedAdmitted, label: 'patients.patientStatus_hospitalizedAdmitted', checked: true },
  { value: PatientStatus.PatientNeverStarted, label: 'patients.patientStatus_patientNeverStarted', checked: true }
];

export const VISIT_STATUS_CHOICES = [
  { value: VisitStatus.available, label: 'visitStatuses.available', checked: true },
  { value: VisitStatus.canceled, label: 'visitStatuses.canceled', checked: true },
  { value: VisitStatus.offered, label: 'visitStatuses.offered', checked: true },
  { value: VisitStatus.scheduled, label: 'visitStatuses.scheduled', checked: true },
  { value: VisitStatus.missed, label: 'visitStatuses.missed', checked: true },
  { value: VisitStatus.enRoute, label: 'visitStatuses.enRoute', checked: true },
  { value: VisitStatus.inProgress, label: 'visitStatuses.inProgress', checked: true },
  { value: VisitStatus.completed, label: 'visitStatuses.completed', checked: true }
];

export const AVAILABLE_STATUS_CHOICES = [
  { value: AvailableStatusEnum.Available, label: 'dispatchScreen.mapVisitStatus_available', checked: true },
  { value: AvailableStatusEnum.NotAvailable, label: 'dispatchScreen.mapVisitStatus_notAvailable', checked: true }
];

export const REPORT_TYPE_OPTIONS = [
  { value: ReportType.BillingVsPayrollReport, label: 'reportTypes.billingVsPayroll', checked: true },
  { value: ReportType.EmployeeWorkingHoursReport, label: 'reportTypes.employeeWorkingHours', checked: true },
  { value: ReportType.PayrollHoursByCaregiverReport, label: 'reportTypes.payrollHoursByCaregiver', checked: true },
  { value: ReportType.BatchReport, label: 'reportTypes.batch', checked: true }
];

export const AUTHORIZATION_STATUS_CHOICES = [
  { value: AuthorizationStatus.active, label: 'authorizationStatus.active' },
  { value: AuthorizationStatus.inactive, label: 'authorizationStatus.inactive' },
  { value: AuthorizationStatus.expired, label: 'authorizationStatus.expired' },
];

export const AUTHORIZATION_PERIOD_TYPE_CHOICES = [
  { value: AuthorizationPeriod.Daily, label: 'authorizationPeriod.daily' },
  { value: AuthorizationPeriod.Weekly, label: 'authorizationPeriod.weekly' },
  { value: AuthorizationPeriod.Monthly, label: 'authorizationPeriod.monthly' },
  { value: AuthorizationPeriod.Entire, label: 'authorizationPeriod.entire' },
];

export const USA_STATES_CHOICES = [
  { value: 'AL', label: 'Alabama (AL)' },
  { value: 'AK', label: 'Alaska (AK)' },
  { value: 'AS', label: 'American Samoa (AS)' },
  { value: 'AZ', label: 'Arizona (AZ)' },
  { value: 'AR', label: 'Arkansas (AR)' },
  { value: 'CA', label: 'California (CA)' },
  { value: 'CO', label: 'Colorado (CO)' },
  { value: 'CT', label: 'Connecticut (CT)' },
  { value: 'DE', label: 'Delaware (DE)' },
  { value: 'DC', label: 'District of Columbia (DC)' },
  { value: 'FL', label: 'Florida (FL)' },
  { value: 'GA', label: 'Georgia (GA)' },
  { value: 'GU', label: 'Guam (GU)' },
  { value: 'HI', label: 'Hawaii (HI)' },
  { value: 'ID', label: 'Idaho (ID)' },
  { value: 'IL', label: 'Illinois (IL)' },
  { value: 'IN', label: 'Indiana (IN)' },
  { value: 'IA', label: 'Iowa (IA)' },
  { value: 'KS', label: 'Kansas (KS)' },
  { value: 'KY', label: 'Kentucky (KY)' },
  { value: 'LA', label: 'Louisiana (LA)' },
  { value: 'ME', label: 'Maine (ME)' },
  { value: 'MD', label: 'Maryland (MD)' },
  { value: 'MA', label: 'Massachusetts (MA)' },
  { value: 'MI', label: 'Michigan (MI)' },
  { value: 'MN', label: 'Minnesota (MN)' },
  { value: 'MS', label: 'Mississippi (MS)' },
  { value: 'MO', label: 'Missouri (MO)' },
  { value: 'MT', label: 'Montana (MT)' },
  { value: 'NE', label: 'Nebraska (NE)' },
  { value: 'NV', label: 'Nevada (NV)' },
  { value: 'NH', label: 'New Hampshire (NH)' },
  { value: 'NJ', label: 'New Jersey (NJ)' },
  { value: 'NM', label: 'New Mexico (NM)' },
  { value: 'NY', label: 'New York (NY)' },
  { value: 'NC', label: 'North Carolina (NC)' },
  { value: 'ND', label: 'North Dakota (ND)' },
  { value: 'MP', label: 'Northern Mariana Islands (MP)' },
  { value: 'OH', label: 'Ohio (OH)' },
  { value: 'OK', label: 'Oklahoma (OK)' },
  { value: 'OR', label: 'Oregon (OR)' },
  { value: 'PA', label: 'Pennsylvania (PA)' },
  { value: 'PR', label: 'Puerto Rico (PR)' },
  { value: 'RI', label: 'Rhode Island (RI)' },
  { value: 'SC', label: 'South Carolina (SC)' },
  { value: 'SD', label: 'South Dakota (SD)' },
  { value: 'TN', label: 'Tennessee (TN)' },
  { value: 'TX', label: 'Texas (TX)' },
  { value: 'UT', label: 'Utah (UT)' },
  { value: 'VT', label: 'Vermont (VT)' },
  { value: 'VI', label: 'Virgin Islands (VI)' },
  { value: 'VA', label: 'Virginia (VA)' },
  { value: 'WA', label: 'Washington (WA)' },
  { value: 'WV', label: 'West Virginia (WV)' },
  { value: 'WI', label: 'Wisconsin (WI)' },
  { value: 'WY', label: 'Wyoming (WY)' }
];

export const MONTH_CHOICES = [
  { value: MonthEnum.January, label: 'fullMonths.january' },
  { value: MonthEnum.February, label: 'fullMonths.february' },
  { value: MonthEnum.March, label: 'fullMonths.march' },
  { value: MonthEnum.April, label: 'fullMonths.april' },
  { value: MonthEnum.May, label: 'fullMonths.may' },
  { value: MonthEnum.June, label: 'fullMonths.june' },
  { value: MonthEnum.July, label: 'fullMonths.july' },
  { value: MonthEnum.August, label: 'fullMonths.august' },
  { value: MonthEnum.September, label: 'fullMonths.september' },
  { value: MonthEnum.October, label: 'fullMonths.october' },
  { value: MonthEnum.November, label: 'fullMonths.november' },
  { value: MonthEnum.December, label: 'fullMonths.december' }
];

export const RACE_CHOICES = [
  { value: 0, label: 'common.humanRace_americanIndianOrAlaskaNative' },
  { value: 1, label: 'common.humanRace_asian' },
  { value: 2, label: 'common.humanRace_blackOrAfricanAmerican' },
  { value: 3, label: 'common.humanRace_nativeHawaiianOrOtherPacificIslander' },
  { value: 4, label: 'common.humanRace_white' },
  { value: 5, label: 'common.humanRace_other' }
];

export const ETHNICITY_CHOICES = [
  { value: 0, label: 'common.humanEthnicity_hispanicOrLatino' },
  { value: 1, label: 'common.humanEthnicity_notHispanicOrLatino' },
  { value: 2, label: 'common.humanEthnicity_other' }
];

export const PATIENT_ADDRESS_TYPE_CHOICES = [
  { value: 0, label: 'patients.patientAddressType_home' },
  { value: 1, label: 'patients.patientAddressType_community' }
];

export const PHYSICIAN_TYPES = [
  { value: 0, label: 'physicians.physicianType_cardiologist' },
  { value: 1, label: 'physicians.physicianType_dermatologist' },
  { value: 2, label: 'physicians.physicianType_gastroenterologist' },
  { value: 3, label: 'physicians.physicianType_generalPractitioner' },
  { value: 4, label: 'physicians.physicianType_internist' },
  { value: 5, label: 'physicians.physicianType_neurologist' },
  { value: 6, label: 'physicians.physicianType_oncologist' },
  { value: 7, label: 'physicians.physicianType_podiatrist' },
  { value: 8, label: 'physicians.physicianType_pulmonologist' },
  { value: 9, label: 'physicians.physicianType_rheumatologist' },
  { value: 10, label: 'physicians.physicianType_urologist' },
  { value: 11, label: 'physicians.physicianType_doctor' },
  { value: 12, label: 'physicians.physicianType_other' },
  { value: 13, label: 'physicians.physicianType_primaryCareDoctor' }
];

export const RELATIONSHIP_CHOICES = [
  { value: 0, label: 'common.userRelationship_aunt' },
  { value: 1, label: 'common.userRelationship_brother' },
  { value: 2, label: 'common.userRelationship_brotherInLaw' },
  { value: 3, label: 'common.userRelationship_child' },
  { value: 4, label: 'common.userRelationship_cousin' },
  { value: 5, label: 'common.userRelationship_daughter' },
  { value: 6, label: 'common.userRelationship_father' },
  { value: 7, label: 'common.userRelationship_fatherInLaw' },
  { value: 8, label: 'common.userRelationship_friend' },
  { value: 9, label: 'common.userRelationship_granddaughter' },
  { value: 10, label: 'common.userRelationship_grandparent' },
  { value: 11, label: 'common.userRelationship_grandson' },
  { value: 12, label: 'common.userRelationship_husband' },
  { value: 13, label: 'common.userRelationship_mother' },
  { value: 14, label: 'common.userRelationship_neighbor' },
  { value: 15, label: 'common.userRelationship_nephew' },
  { value: 16, label: 'common.userRelationship_niece' },
  { value: 17, label: 'common.userRelationship_parent' },
  { value: 18, label: 'common.userRelationship_relative' },
  { value: 19, label: 'common.userRelationship_significantOther' },
  { value: 20, label: 'common.userRelationship_sister' },
  { value: 21, label: 'common.userRelationship_sisterInLaw' },
  { value: 22, label: 'common.userRelationship_son' },
  { value: 23, label: 'common.userRelationship_uncle' },
  { value: 24, label: 'common.userRelationship_wife' },
  { value: 25, label: 'common.userRelationship_other' }
];

export const DIAGNOSIS_DATE_TYPE_CHOICES = [
  { value: DiagnoseDateType.Oncet, label: 'userDetails.diagnoses_popup_diagnosisDateType_onset' },
  { value: DiagnoseDateType.Exacerbation, label: 'userDetails.diagnoses_popup_diagnosisDateType_exacerbation' }
];

export const DIAGNOSIS_IDENTIFIED_CHOICES = [
  { value: DiagnoseIdentified.Referral, label: 'userDetails.diagnoses_popup_diagnosisIdentifying_referral' },
  { value: DiagnoseIdentified.Assessment, label: 'userDetails.diagnoses_popup_diagnosisIdentifying_assessment' }
];

export const PAYER_STATUS_CHOICES = [
  { value: PayerStatus.Active, label: 'visitStatuses.active', checked: true },
  { value: PayerStatus.Inactive, label: 'visitStatuses.inactive', checked: true }
];
export const PAYER_ICD_CHOICES = [
  { value: 0, label: 'ICD-9' },
  { value: 1, label: 'ICD-10' }
];
export const UNIT_EQUALS_CHOICES = [
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' },
  { value: 60, label: '60 min' }
];
export const RATE_TYPE_CHOICES = [
  { value: 0, label: 'settings.payroll_rateType_hourly' },
  { value: 1, label: 'settings.payroll_rateType_daily' },
  { value: 2, label: 'settings.payroll_rateType_visit' },
  { value: 3, label: 'settings.payroll_rateType_monthly' }
];
export const ROUND_ON_CHOICES = [
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' },
  { value: 60, label: '60 min' },
  { value: 0, label: 'No Rounding' }
];
export const OPTION_CHOICES = [
  { value: 0, label: 'Closest' },
  { value: 1, label: 'Up' },
  { value: 2, label: 'Down' }
];
export const PAY_CYCLE_CHOICES = [
  { value: 0, label: 'settings.payroll_payCycle_daily' },
  { value: 1, label: 'settings.payroll_payCycle_weekly' },
  { value: 2, label: 'settings.payroll_payCycle_biWeekly' },
  { value: 3, label: 'settings.payroll_payCycle_monthly' }
];

export const EDI_PROVIDER_TYPES_CHOICES = [
  { value: EdiProviderType.Waystar, label: 'settings.billing_ediProviders_providerType_waystar' },
  { value: EdiProviderType.CHC, label: 'settings.billing_ediProviders_providerType_chc' }
];

export const EDI_METHOD_TYPES_CHOICES = [
  { value: EdiProviderProcessingMethod.Automatically, label: 'settings.billing_ediProviders_methodType_automatically' },
  { value: EdiProviderProcessingMethod.Manually, label: 'settings.billing_ediProviders_methodType_manually' }
];

export const MARITAL_STATUS_CHOICES = [
  { value: MaritalStatus.Married, label: 'common.maritalStatus_married' },
  { value: MaritalStatus.Divorced, label: 'common.maritalStatus_divorced' },
  { value: MaritalStatus.Separated, label: 'common.maritalStatus_separated' },
  { value: MaritalStatus.Widowed, label: 'common.maritalStatus_widowed' },
  { value: MaritalStatus.Single, label: 'common.maritalStatus_single' },
];

export const SMOKER_TYPE_CHOICES = [
  { value: SmokerType.Tobacco, label: 'common.tobacco' },
  { value: SmokerType.Cigars, label: 'common.cigars' },
  { value: SmokerType.Vape, label: 'common.vape' },
  { value: SmokerType.Hookah, label: 'common.hookah' },
  { value: SmokerType.Marijuana, label: 'common.marijuana' },
];

export const OPTION_TIME_ZONE = TIME_ZONE.map((item: string) => ({ value: item, label: item }));

export const menuTabsName = {
  ACCOUNT_INFORMATION: 'tabMenu.AccountInformation',
  AGENCY: 'tabMenu.Agency',
  ALLERGIES: 'tabMenu.Allergies',
  APPLICANTS: 'tabMenu.Applicants',
  APPLICATIONS: 'tabMenu.Applications',
  APPOINTMENTS: 'tabMenu.Appointments',
  ASSIGN_BLASTING: 'tabMenu.AssignBlasting',
  AVAILABILITY: 'tabMenu.Availability',
  AUTHORIZATIONS: 'tabMenu.Authorizations',
  BILLING: 'tabMenu.Billing',
  CALENDAR: 'tabMenu.Calendar',
  CAREGIVER_INFORMATION: 'tabMenu.CaregiverInformation',
  CLIENT_INFORMATION: 'tabMenu.ClientInformation',
  COMMUNICATION: 'tabMenu.Communication',
  CONTACTS_AND_ADDRESS: 'tabMenu.ContactsAddress',
  DIAGNOSES: 'tabMenu.Diagnoses',
  DISCIPLINES: 'tabMenu.Services',
  DOCUMENT_MANAGEMENT: 'tabMenu.DocumentManagement',
  DUTIES: 'tabMenu.Duties',
  EMERGENCY_CONTACT: 'tabMenu.EmergencyContact',
  EXPENSES: 'tabMenu.Expenses',
  GENERAL: 'tabMenu.General',
  GENERAL_INFORMATION: 'tabMenu.GeneralInformation',
  HISTORY: 'tabMenu.History',
  IN_SERVICE: 'tabMenu.InService',
  MASTERWEEK: 'tabMenu.Masterweek',
  MAIN_SETTINGS: 'tabMenu.MainSettings',
  MESSAGE_CENTER: 'tabMenu.MessageCenter',
  NOTES: 'tabMenu.Notes',
  OFFICES: 'tabMenu.Offices',
  OPEN_POSITIONS: 'tabMenu.OpenPositions',
  PATIENT_INFORMATION: 'tabMenu.PatientInformation',
  PATIENT_WORKFLOW: 'tabMenu.PatientWorkflow',
  PAYCHECK: 'tabMenu.Paycheck',
  PAYER_INFORMATION: 'tabMenu.PayerInformation',
  PAYROLL: 'tabMenu.Payroll',
  COMPLIANCE: 'tabMenu.Compliance',
  PERMISSIONS: 'tabMenu.Permissions',
  PERSONAL_INFORMATION: 'tabMenu.PersonalInformation',
  PLAN_OF_CARE: 'tabMenu.PlanOfCare',
  POSITION_INFORMATION: 'tabMenu.PositionInformation',
  PRICING: 'tabMenu.Pricing',
  PREFERENCES: 'tabMenu.Preferences',
  PRIMARY_INFO: 'tabMenu.PrimaryInfo',
  RATES: 'tabMenu.Rates',
  REASONS: 'tabMenu.Reasons',
  REPORTS: 'tabMenu.Reports',
  RESUME: 'tabMenu.Resume',
  SAVED_TEMPLATES: 'tabMenu.SavedTemplates',
  SERVICE_SETUP: 'tabMenu.ServiceSetup',
  USER_INFORMATION: 'tabMenu.UserInformation',
  VISITS: 'tabMenu.Visits',
  VISIT_INFO: 'tabMenu.VisitInfo'
};

export const dialogModesList = {
  ADD: 'add',
  EDIT: 'edit',
  SAVE: 'save',
  CREATE: 'create'
};

export const formStatuses = {
  VALID: 'VALID',
  INVALID: 'INVALID'
};

export const ALLOWED_MIME_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const EXTENDED_ALLOWED_MIME_TYPES = [
  ...ALLOWED_MIME_TYPES,
  'image/bmp',
  'application/zip',
  'application/rtf',
  'text/rtf',
  'application/vnd.rar',
  'application/x-rar',
  'application/x-rar-compressed',
  '.rar',
];

export const INTERVIEW_STATUSES = [
  { value: 1, label: 'calendar.interviewStatus_scheduled', checked: false },
  { value: 2, label: 'calendar.interviewStatus_canceled', checked: false },
  { value: 3, label: 'calendar.interviewStatus_noShow', checked: false },
  { value: 4, label: 'calendar.interviewStatus_completed', checked: false }
];

export const APPOINTMENT_STATUSES = [
  { value: 0, label: 'userDetails.appointmentStatus_active' },
  { value: 1, label: 'userDetails.appointmentStatus_inactive' }
];

export const APPOINTMENT_DOSE_TYPES = [
  { value: 0, label: 'Mg' },
  { value: 1, label: 'Ml' },
  { value: 2, label: 'PCS' }
];

export const APPOINTMENT_FREQUENCY = [
  { value: 0, label: 'userDetails.appointmentFrequency_daily' },
  { value: 1, label: 'userDetails.appointmentFrequency_twiceDaily' },
  { value: 2, label: 'userDetails.appointmentFrequency_thriceDaily' }
];

export const IN_SERVICE_STATUSES = [
  { value: InServiceStatus.Scheduled, label: 'caregiverDetails.inServiceStatus_scheduled' },
  { value: InServiceStatus.Completed, label: 'caregiverDetails.inServiceStatus_completed' },
  { value: InServiceStatus.NotCompleted, label: 'caregiverDetails.inServiceStatus_notCompleted' }
];

export const IN_SERVICE_REASONS = [
  { value: 0, label: 'caregiverDetails.inServiceReason_missed' },
  { value: 1, label: 'caregiverDetails.inServiceReason_declined' }
];

export const APPLICATION_STATUSES = [
  { value: 0, checked: true, label: 'applicantDetails.applicationStatus_new' },
  { value: 1, checked: true, label: 'applicantDetails.applicationStatus_inProgress' },
  { value: 2, checked: true, label: 'applicantDetails.applicationStatus_hired' },
  { value: 3, checked: true, label: 'applicantDetails.applicationStatus_rejected' }
];

export const COMPANY_TYPES = {
  HR: 'hr',
  HOMECARE: 'homecare',
  HOMECARE_HR: 'homecare-hr'
};

export const MENU_ITEMS_LINKS = {
  DISPATCH_SCREEN: '/dispatch-screen',
  SCHEDULING: '/scheduling',
  VISITS: '/visits',
  ACTIVE_BLASTING: '/active-blasting',
  AVAILABLE_MASTERWEEKS: '/available-masterweeks',
  PATIENTS: '/patients',
  MESSAGE_CENTER: '/message-center',
  USERS: '/users',
  PHYSICIANS: '/physicians',
  DUTIES: '/duties',
  PAYERS: '/payers',
  MAIN_BATCH: '/main-batches',
  PAYROLL_BATCH: '/payroll-batches',
  INVOICING: '/invoicing',
  PRIVATE_PAY: '/private-pay',
  OPEN_POSITIONS: '/open-positions',
  APPLICANTS: '/applicants',
  CALENDAR: '/calendar',
  DATA_IMPORT: '/data-import',
  REPORTS: '/reports',
  SETTINGS: '/settings'
};

export const MENU_ITEMS_NAMES = {
  ACTIVE_BLASTING: 'mainMenu.activeBlasting',
  ALL_VISITS: 'mainMenu.allVisits',
  ALL_PAYERS: 'mainMenu.allPayers',
  ALL_USERS: 'mainMenu.allUsers',
  APPLICANTS: 'mainMenu.applicants',
  AVAILABLE_MASTERWEEKS: 'mainMenu.availableMasterweeks',
  BILLING: 'mainMenu.billing',
  CALENDAR: 'mainMenu.calendar',
  DATA_IMPORT: 'mainMenu.dataImport',
  DISPATCH_SCREEN: 'mainMenu.dispatchScreen',
  SCHEDULING: 'mainMenu.scheduling',
  DUTIES: 'mainMenu.duties',
  EDI_BATCH: 'mainMenu.ediBatch',
  HR: 'mainMenu.hr',
  INVOICING: 'mainMenu.invoicing',
  MAIN_BATCH: 'mainMenu.mainBatch',
  MESSAGE_CENTER: 'mainMenu.messageCenter',
  OPEN_POSITIONS: 'mainMenu.openPositions',
  PATIENTS: 'mainMenu.patients',
  PAYERS: 'mainMenu.payers',
  PAYROLL: 'mainMenu.payroll',
  PAYROLL_BATCH: 'mainMenu.payrollBatch',
  PHYSICIANS: 'mainMenu.physicians',
  PRIVATE_PAY: 'mainMenu.privatePay',
  REPORTS: 'mainMenu.reports',
  SETTINGS: 'mainMenu.settings',
  USERS: 'mainMenu.users',
  VISITS: 'mainMenu.visits'
};

export const MENU_ITEMS_ATTENTION_KEY = {
  ACTIVE_BLASTING: 'active_blasting',
  AVAILABLE_MASTERWEEKS: 'available_master_weeks'
};

export const MENU_ITEMS_PERMISSION_KEY = {
  DISPATCH: 'tab_access_dispatch_screen',
  SCHEDULING: 'tab_access_weekly_scheduling',
  VISITS: 'tab_access_visits',
  ACTIVE_BLASTING: 'tab_access_active_blasting',
  AVAILABLE_MASTERWEEKS: 'tab_access_available_master_weeks',
  PATIENTS: 'tab_access_patients',
  MESSAGE_CENTER: 'tab_access_communication_center',
  USERS: 'tab_access_users',
  PHYSICIANS: 'tab_access_physicians',
  DUTIES: 'tab_access_duties',
  PAYERS: 'tab_access_payers',
  BILLING: 'tab_access_billing',
  OPEN_POSITIONS: 'tab_access_hr_open_positions',
  APPLICANTS: 'tab_access_hr_applicants',
  CALENDAR: 'tab_access_hr_calendar',
  DATA_IMPORT: 'tab_access_hr_data_import',
  REPORTS: 'tab_access_reports',
  SETTINGS: 'tab_access_settings'
};

export const shortDaysOfWeek = [
  'shortDaysOfWeek.monday',
  'shortDaysOfWeek.tuesday',
  'shortDaysOfWeek.wednesday',
  'shortDaysOfWeek.thursday',
  'shortDaysOfWeek.friday',
  'shortDaysOfWeek.saturday',
  'shortDaysOfWeek.sunday'
];

export const COUNTRY_CODE_OPTIONS = [
  { value: '+1', label: '+1', checked: false },
  { value: '+38', label: '+38', checked: false }
];

export const COUNTRY_CODE_OPTIONS_ONLY_USA = [
  { value: '+1', label: '+1', checked: false }
];

export const WEEKEND_DAYS_OPTIONS = [
  { value: 0, label: 'fullDaysOfWeek.monday' },
  { value: 1, label: 'fullDaysOfWeek.tuesday' },
  { value: 2, label: 'fullDaysOfWeek.wednesday' },
  { value: 3, label: 'fullDaysOfWeek.thursday' },
  { value: 4, label: 'fullDaysOfWeek.friday' },
  { value: 5, label: 'fullDaysOfWeek.saturday' },
  { value: 6, label: 'fullDaysOfWeek.sunday' }
];

export const InvoiceBatchClaimMethods = {
  EDI: 0,
  PrivatePay: 1
};

export const MENU_ITEMS: MenuItem[] = [
  {
    name: MENU_ITEMS_NAMES.DISPATCH_SCREEN,
    icon: 'dispatch',
    link: MENU_ITEMS_LINKS.DISPATCH_SCREEN,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.DISPATCH
  },
  {
    name: MENU_ITEMS_NAMES.SCHEDULING,
    icon: 'scheduling',
    link: MENU_ITEMS_LINKS.SCHEDULING,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.SCHEDULING
  },
  {
    name: MENU_ITEMS_NAMES.VISITS, icon: 'visits', active: false,
    submenu: [
      {
        name: MENU_ITEMS_NAMES.ALL_VISITS,
        icon: 'visits',
        link: MENU_ITEMS_LINKS.VISITS,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.VISITS
      },
      {
        name: MENU_ITEMS_NAMES.ACTIVE_BLASTING,
        icon: 'active-blasting',
        link: MENU_ITEMS_LINKS.ACTIVE_BLASTING,
        active: false,
        attentionKey: MENU_ITEMS_ATTENTION_KEY.ACTIVE_BLASTING,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.ACTIVE_BLASTING
      },
      {
        name: MENU_ITEMS_NAMES.AVAILABLE_MASTERWEEKS,
        icon: 'payers',
        link: MENU_ITEMS_LINKS.AVAILABLE_MASTERWEEKS,
        active: false,
        attentionKey: MENU_ITEMS_ATTENTION_KEY.AVAILABLE_MASTERWEEKS,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.AVAILABLE_MASTERWEEKS
      }
    ]
  },
  {
    name: MENU_ITEMS_NAMES.PATIENTS,
    icon: 'patients',
    link: MENU_ITEMS_LINKS.PATIENTS,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.PATIENTS
  },
  {
    name: MENU_ITEMS_NAMES.MESSAGE_CENTER,
    icon: 'chat',
    link: MENU_ITEMS_LINKS.MESSAGE_CENTER,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.MESSAGE_CENTER
  },
  {
    name: MENU_ITEMS_NAMES.USERS, icon: 'users', active: false,
    submenu: [
      {
        name: MENU_ITEMS_NAMES.ALL_USERS,
        icon: 'users',
        link: MENU_ITEMS_LINKS.USERS,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.USERS
      },
      {
        name: MENU_ITEMS_NAMES.PHYSICIANS,
        icon: 'physicians',
        link: MENU_ITEMS_LINKS.PHYSICIANS,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.PHYSICIANS
      }
    ]
  },
  {
    name: MENU_ITEMS_NAMES.DUTIES,
    icon: 'duties',
    link: MENU_ITEMS_LINKS.DUTIES,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.DUTIES
  },
  {
    name: MENU_ITEMS_NAMES.PAYERS, icon: 'payers', active: false,
    submenu: [
      {
        name: MENU_ITEMS_NAMES.ALL_PAYERS,
        icon: 'payers',
        link: MENU_ITEMS_LINKS.PAYERS,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.PAYERS
      },
      {
        name: MENU_ITEMS_NAMES.BILLING,
        icon: 'batches',
        link: MENU_ITEMS_LINKS.MAIN_BATCH,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.BILLING
      },
      // { name: MENU_ITEMS_NAMES.PAYROLL_BATCH, icon: 'batches', link: MENU_ITEMS_LINKS.PAYROLL_BATCH, active: false },
      // { name: MENU_ITEMS_NAMES.INVOICING, icon: 'invoicing', link: MENU_ITEMS_LINKS.INVOICING, active: false },
      // { name: MENU_ITEMS_NAMES.PRIVATE_PAY, icon: 'private-pay', link: MENU_ITEMS_LINKS.PRIVATE_PAY, active: false }
    ]
  },
  {
    name: MENU_ITEMS_NAMES.HR, icon: 'open-positions', active: false,
    submenu: [
      {
        name: MENU_ITEMS_NAMES.OPEN_POSITIONS,
        icon: 'open-positions',
        link: MENU_ITEMS_LINKS.OPEN_POSITIONS,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.OPEN_POSITIONS
      },
      {
        name: MENU_ITEMS_NAMES.APPLICANTS,
        icon: 'applicants',
        link: MENU_ITEMS_LINKS.APPLICANTS,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.APPLICANTS
      },
      {
        name: MENU_ITEMS_NAMES.CALENDAR,
        icon: 'calendar',
        link: MENU_ITEMS_LINKS.CALENDAR,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.CALENDAR
      },
      {
        name: MENU_ITEMS_NAMES.DATA_IMPORT,
        icon: 'data-import',
        link: MENU_ITEMS_LINKS.DATA_IMPORT,
        active: false,
        permissionKey: MENU_ITEMS_PERMISSION_KEY.DATA_IMPORT
      }
    ]
  },
  {
    name: MENU_ITEMS_NAMES.REPORTS,
    icon: 'reports',
    link: MENU_ITEMS_LINKS.REPORTS,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.REPORTS
  },
  {
    name: MENU_ITEMS_NAMES.SETTINGS,
    icon: 'settings',
    link: MENU_ITEMS_LINKS.SETTINGS,
    active: false,
    permissionKey: MENU_ITEMS_PERMISSION_KEY.SETTINGS
  }
];

export const SHOT_OPTIONS: OptionsListItemIfc[] = [
  { value: ShotOption.SHOT, label: 'caregiverDetails.compliance_shot', checked: false },
  { value: ShotOption.DECLINED, label: 'caregiverDetails.inServiceReason_declined', checked: false }
];

export const COMPLIANCE_REFERENCE_OPTIONS: OptionsListItemIfc[] = [
  { value: ComplianceReferenceOption.WORK, label: 'caregiverDetails.compliance_work', checked: false },
  { value: ComplianceReferenceOption.CHARACTER, label: 'caregiverDetails.compliance_character', checked: false }
];

export const CRIMINAL_BACKGROUND_RESULT_OPTIONS: Omit<OptionsListItemIfc, 'checked'>[] = [
  { value: CriminalBackgroundResultOption.CDPA_NOT_NECESSARY, label: 'caregiverDetails.compliance_cdpaNotNecessary' },
  { value: CriminalBackgroundResultOption.DENIED, label: 'caregiverDetails.compliance_denied' },
  { value: CriminalBackgroundResultOption.HOLD_IN_ABEYANCE, label: 'caregiverDetails.compliance_holdInAbeyance' },
  { value: CriminalBackgroundResultOption.NEEDS_FINGERPRINTING, label: 'caregiverDetails.compliance_needsFingerprinting' },
  { value: CriminalBackgroundResultOption.PASSED, label: 'caregiverDetails.compliance_passed' },
  { value: CriminalBackgroundResultOption.PENDING, label: 'caregiverDetails.compliance_pending' },
  { value: CriminalBackgroundResultOption.SUBMITTED, label: 'caregiverDetails.compliance_submitted' },
];

export const A_DOCUMENT_OPTIONS: Omit<OptionsListItemIfc, 'checked'>[] = [
  { value: ADocumentOption.US_PASSPORT_OR_PASSPORT_CARD, label: 'caregiverDetails.compliance_usPassportOrPassportCard' },
  { value: ADocumentOption.PERM_RESIDENT_CARD, label: 'caregiverDetails.compliance_permResidentCard' },
  { value: ADocumentOption.FOREIGN_PASSPORT_WITH_I551, label: 'caregiverDetails.compliance_foreignPassportWithI551' },
  { value: ADocumentOption.EMPLOYMENT_AUTHORIZATION_DOCUMENT, label: 'caregiverDetails.compliance_employmentAuthorizationDocument' },
  { value: ADocumentOption.FOREIGN_PASSPORT_WITH_I94, label: 'caregiverDetails.compliance_foreignPassportWithI94' },
  { value: ADocumentOption.PASSPORT_FROM_FSM_OR_RMI_WITH_I94, label: 'caregiverDetails.compliance_passportFromFsmOrRmiWithI94' },
];

export const B_DOCUMENT_OPTIONS = [
  { value: BCDocumentOption.DRIVER_LICENSE, label: 'caregiverDetails.compliance_driverLicense_1-B' },
  { value: BCDocumentOption.ID_CARD, label: 'caregiverDetails.compliance_idCard' },
  { value: BCDocumentOption.SCHOOL_ID, label: 'caregiverDetails.compliance_schoolId' },
  { value: BCDocumentOption.VOTERS_REGISTRATION_CARD, label: 'caregiverDetails.compliance_voterRegistrationCard' },
  { value: BCDocumentOption.US_MILITARY_CARD, label: 'caregiverDetails.compliance_usMilitaryCard' },
  { value: BCDocumentOption.MILITARY_DEPENDENTS_ID_CARD, label: 'caregiverDetails.compliance_militaryDependentsIdCard' },
  { value: BCDocumentOption.US_COAST_GUARD_CARD, label: 'caregiverDetails.compliance_usCoastGuardCard' },
  { value: BCDocumentOption.NATIVE_AMERICAN_TRIBAL_B_DOCUMENT, label: 'caregiverDetails.compliance_nativeAmericanTribalDocument_8-B' },
  { value: BCDocumentOption.CANADIAN_DRIVERS_LICENSE, label: 'caregiverDetails.compliance_canadianDriverLicense' },
  { value: BCDocumentOption.ALIEN_RESIDENT_CARD, label: 'caregiverDetails.compliance_alienResidentCard' },
  { value: BCDocumentOption.OTHER_B, label: 'caregiverDetails.compliance_other_11-B' },
  { value: BCDocumentOption.ALIEN_REGISTRATION_RECEIPT, label: 'caregiverDetails.compliance_alienRegistrationReceipt' },
];

export const C_DOCUMENT_OPTIONS: Omit<OptionsListItemIfc, 'checked'>[] = [
  { value: BCDocumentOption.SOCIAL_SECURITY_CARD, label: 'caregiverDetails.compliance_socialSecurityCard' },
  { value: BCDocumentOption.CERTIFICATION_OF_REPORT_OR_BIRTH, label: 'caregiverDetails.compliance_certificationOfReportBirth' },
  { value: BCDocumentOption.BIRTH_CERTIFICATE, label: 'caregiverDetails.compliance_birthCertificate' },
  { value: BCDocumentOption.NATIVE_AMERICAN_TRIBAL_C_DOCUMENT, label: 'caregiverDetails.compliance_nativeAmericanTribalDocument_4-C' },
  { value: BCDocumentOption.US_CITIZEN_ID_CARD, label: 'caregiverDetails.compliance_usCitizenIdCard' },
  { value: BCDocumentOption.IDENTIFICATION_CARD, label: 'caregiverDetails.compliance_identificationCard' },
  { value: BCDocumentOption.EMPLOYMENT_AUTHORIZATION, label: 'caregiverDetails.compliance_employmentAuthorization' },
  { value: BCDocumentOption.OTHER_C, label: 'caregiverDetails.compliance_other_8-C' },
  { value: BCDocumentOption.CERTIFICATE_OF_NATURALIZATION, label: 'caregiverDetails.compliance_certificateOfNaturalization' }
];

export const TRANSPORTATION_TYPE_OPTIONS = [
  { value: TransportationType.CarTransport, label: 'settings.mainSettings_group_assignBlasting_form_transportation_car' },
  { value: TransportationType.PublicTransport, label: 'settings.mainSettings_group_assignBlasting_form_transportation_publicTransport' },
  { value: TransportationType.Walking, label: 'settings.mainSettings_group_assignBlasting_form_transportation_walking' },
  { value: TransportationType.Other, label: 'settings.mainSettings_group_assignBlasting_form_transportation_other' },
];

export const NOTICE_TIME_OPTIONS = [
  { value: NoticeTime.OnCall, label: 'settings.mainSettings_group_assignBlasting_noticeTime_onCall' },
  { value: NoticeTime.Hour1, label: 'settings.mainSettings_group_assignBlasting_noticeTime_hour' },
  { value: NoticeTime.Hours2, label: 'settings.mainSettings_group_assignBlasting_noticeTime_2hours' },
  { value: NoticeTime.Hours3, label: 'settings.mainSettings_group_assignBlasting_noticeTime_3hours' },
  { value: NoticeTime.Hours6, label: 'settings.mainSettings_group_assignBlasting_noticeTime_6hours' },
];

export const DOWNTIME_INTERVAL_OPTION = [
  { value: DowntimeInterval.NoDowntime, label: 'settings.mainSettings_group_assignBlasting_downtime_no' },
  { value: DowntimeInterval.Min15, label: 'settings.mainSettings_group_assignBlasting_downtime_15min' },
  { value: DowntimeInterval.Min30, label: 'settings.mainSettings_group_assignBlasting_downtime_30min' },
  { value: DowntimeInterval.Hour1, label: 'settings.mainSettings_group_assignBlasting_downtime_hour' },
  { value: DowntimeInterval.MoreThenHour, label: 'settings.mainSettings_group_assignBlasting_downtime_moreThanHour' },
];

export const BLASTING_STATUSES_OPTIONS = [
  { value: BlastingStatus.opened, label: 'visitStatuses.opened' },
  { value: BlastingStatus.completed, label: 'visitStatuses.completed' },
  { value: BlastingStatus.cancelled, label: 'visitStatuses.canceled' }
];

// Notifications
export const NOTIFICATION_TYPES = [
  { value: 'all', label: 'common.all' },
  { value: NotificationType.Visit, label: 'notificationCenter.notificationType_visit' },
  { value: NotificationType.Blasting, label: 'notificationCenter.notificationType_blasting' },
  { value: NotificationType.Patient, label: 'notificationCenter.notificationType_patient' },
  { value: NotificationType.User, label: 'notificationCenter.notificationType_user' },
  { value: NotificationType.Report, label: 'notificationCenter.notificationType_report' }
];

export const RED_NOTIFICATIONS: NotificationSubType[] = [
  NotificationSubType.MissedVisit,
  NotificationSubType.CanceledVisit,
  NotificationSubType.AuthTimeExpired,
  NotificationSubType.MwAppointment1D,
  NotificationSubType.VisitOvertime,
  NotificationSubType.VisitOverlapping
];

export const YELLOW_NOTIFICATIONS: NotificationSubType[] = [
  NotificationSubType.NotClockoutVisit,
  NotificationSubType.NotClockinVisit,
  NotificationSubType.AcceptedOfferAll,
  NotificationSubType.AuthTimeExpire2D,
  NotificationSubType.MwAppointment3D
];

export const GREEN_NOTIFICATIONS: NotificationSubType[] = [
  NotificationSubType.AcceptedOffer
];

export const GRAY_NOTIFICATIONS: NotificationSubType[] = [
  NotificationSubType.AuthTimeExpire1W,
  NotificationSubType.MwAppointment1W,
  NotificationSubType.ReportSharing
];
